const isAuthenticated = ({ user }) => !!user;

const isSigning = ({ isSigningLogin }) => isSigningLogin;

const passwordFieldErrors = ({ newPasswordFieldErrors }) => newPasswordFieldErrors;

export default {
  isAuthenticated,
  isSigning,
  passwordFieldErrors,
};
