export default {
  GENERIC_MUTATION(state, { property, payload }) {
    state[property] = payload;
  },
  UPDATE_FIELD_LOGIN(state, { property, payload }) {
    state.login[property] = payload;
  },
  UPDATE_FIELD_RECOVER(state, { property, payload }) {
    state.recover[property] = payload;
  },
  UPDATE_FIELD_CREATE(state, { property, payload }) {
    state.createPassword[property] = payload;
  },
  UPDATE_FIELD_NEW(state, { property, payload }) {
    state.newPassword[property] = payload;
  },
};
