<template>
  <v-img v-bind="{ contain, height, width }" src="@/assets/zitrus_logo.svg" />
</template>

<script>
export default {
  name: 'ZLogo',
  props: {
    height: {
      type: String,
      default: '75px',
    },
    width: {
      type: String,
      default: '162px',
    },
    contain: Boolean,
  },
};
</script>
