const formLogin = {
  userLogin: '',
  password: '',
  type: '',
};

const formRecover = {
  userLogin: '',
};

const formCreatePassword = {
  newPassword: '',
  confirmationNewPassword: '',
};

const formNewPassword = {
  newPassword: '',
  confirmationNewPassword: '',
};

export default () => ({
  user: null,
  authProviderTypes: { zitrus: 'ZITRUS', azureAD: 'AZURE_ACTIVE_DIRECTORY' },
  login: { ...formLogin },
  recover: { ...formRecover },
  createPassword: { ...formCreatePassword },
  newPassword: { ...formNewPassword },
  newPasswordFieldErrors: [],
  isLoading: false,
  loginStep: 1,
});
