import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'font-awesome/css/font-awesome.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, far);

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md' || 'mdi' || 'mdiSvg' || 'fa' || 'fa4' || 'faSvg',
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});
