<template>
  <v-app id="z-login">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<!-- <style lang="scss">
@import '@/style/themeUpdate.scss';
</style> -->
